<template>
	<div>
		<div class="exceedTab ">
			<h3 class="tit">过夜车辆数</h3>
			<div class="flex">
				<div class="echarts" style="width: 70%;">
					<histogram v-if="parkRate.xValue.length>0" id="overParkLine" type="line" :data="parkRate"
										 ref="overParkLine"></histogram>
				</div>
				<div style="width: 30%;">
					<echartscom id="overPark" v-if="overNightOneDay.chartData.length > 0" :nameData="overNightOneDay.rows"
											:chartData="overNightOneDay.chartData"></echartscom>
				</div>
			</div>
		</div>
		<div class="exceedTab ">
			<h3 class="tit">停车超过24小时车辆</h3>
			<div class="flex">
				<div class="echarts" style="width: 70%;">
					<histogram v-if="overNightLine.xValue.length>0" id="overNightLine" type="line" :data="overNightLine"
										 ref="overNightLine"></histogram>
				</div>
				<div style="width: 30%;">
					<echartscom id="overNightRate" v-if="entryCarOneDay.chartData.length > 0" :nameData="entryCarOneDay.rows"
											:chartData="entryCarOneDay.chartData"></echartscom>
				</div>

			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: "exceedPeriod",
	props: {
		overNightOneDay: {
			type: Object
		},
		parkRate: {
			type: Object
		},
		entryCarOneDay: {
			type: Object
		},
		overNightLine: {
			type: Object
		}
	},
	watch: {
		/*parkRate:{
			handler(newVal){
				if (newVal != ''){
					this.$refs['overParkLine'].drawCharts();
				}
			},
			deep:true
		},
		overNightLine:{
			handler(newVal){
				if (newVal != ''){
					this.$refs['overNightLine'].drawCharts();
				}
			},
			deep:true
		},*/
	},
	mounted() {
		console.log(this.entryCarOneDay);
	},
	methods: {},
	components: {
		"echartscom": () => import("../account/echartscom"),
		"histogram": () => import("./histogram")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/overNight";
</style>
